<template>
	<div class="panel-container">
		<div class="p-2 my-1 d-flex justify-content-between align-items-center container-titulo" @click="toogleVisibility">
			<div class="d-flex flex-row w-100 align-items-center">
				<div v-show="!contentVisible">
					<i class="fas fa-caret-right mr-2"></i>
				</div>
				<div v-show="contentVisible">
					<i class="fas fa-caret-down mr-2"></i>
				</div>
				<slot name="title">{{ title }}</slot>
			</div>
			<div class="mr-2 d-flex justify-content-between align-items-center">
				<slot name="action-button"></slot>
				<div v-show="contentVisible">
					<i class="fas fa-minus-circle"></i>
				</div>
				<div v-show="!contentVisible">
					<i class="fas fa-plus-circle"></i>
				</div>
			</div>
			
		</div>
		<div class="px-0 py-2 m-0 " v-if="contentVisible">
			<slot></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String
		},
		startOpened: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			contentVisible: false
		};
	},
	created() {
		this.contentVisible = this.startOpened;
	},
	watch: {
		startOpened() {
			this.contentVisible = this.startOpened;
		}
	},
	methods: {
		toogleVisibility() {
			this.contentVisible = !this.contentVisible;
		}
	}
};
</script>

<style>
.container-titulo {
	border-bottom: 1px solid #ddd;
	cursor: pointer;
	background-color: #f1f1f1;
}
</style>
